<template>
  <div class="container oci-check-in-out-body">    
    <WasteNumberView/>
    <p></p>
    <b-card class="oci-check-in-out-card">  
        <b-row class="pb-0 justify-content-center">
            <b>Vehicle registration</b>
        </b-row>
        <b-row class="pb-1">
          <b-col cols="12">
            <b-form-group
                label=""
                label-for="name-edit"
                class="text-left w-100"
            >
                <b-form-input
                v-model="bookingDockets.vehicle_registration_number"
                placeholder=""
                name="name-edit"
                :disabled="isInputDisabled"
                />
            </b-form-group>
          </b-col>  
        </b-row>   
        <b-row class="pb-1">
          <b-col class="col-5 oci-projects-button-container">
            <b-button
              type="submit"
              @click="GoToPreviousPage"
              class="w-100"
            >
              <feather-icon
                icon="ChevronLeftIcon"
                size="12"
              />              
              Back
            </b-button>
          </b-col>
          <b-col class="col-7 oci-check-in-button-container">
            <b-button
              type="submit"
              @click="GoToNextPage"
              class="w-100"
            >
              Next
              <feather-icon
                icon="ChevronRightIcon"
                size="12"
              />              
            </b-button>
          </b-col>
        </b-row> 
        <b-row class="pb-1">
          <b-col cols="12">
            <b-button
              type="submit"
              @click="CancelJob"
              class="w-100"
              :disabled="isInputDisabled"
            >
              Cancel job            
            </b-button>
          </b-col>
        </b-row>
        <b-row class="pb-0">
          <b-col cols="12">
            <b-form-group
            label="Reason"
            label-for="waste-bins"
            >          
            <b-form-select
            id="cancel_reason"
            v-model="currentBooking.reason"
            :options="cancelOptions"
            :disabled="isInputDisabled"
            ></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row class="pb-0">
            <b-col>
            <b-form-group
                label="Cancel reason note"
                label-for="name-edit"
                class="text-left w-100"
                v-if="currentBooking.reason=='other'"
            >
                <b-form-input
                v-model="currentBooking.reason_notes"
                placeholder=""
                name="name-edit"
                :disabled="isInputDisabled"
                />
            </b-form-group>
            </b-col>
        </b-row>
        
    </b-card>

  </div>
  </template>

<script>
import { mapGetters } from 'vuex'
import { BImg, BCard, BRow, BCol, BForm, BFormGroup, BFormSelect, BInputGroupAppend, BFormInput, BButton, BInputGroup, } from 'bootstrap-vue'
import { bookingsMethods } from '@/components/ui/BookingsFunctions'
import WasteNumberView from '@/views/WasteNumberView.vue'

export default {
  components: {
    BImg,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BFormInput,
    BButton,
    WasteNumberView,
  },
  data() {
    return {
      reason: '',
      cancelReason: '',
      originalVehicleRegistrationNumber: '',
      cancelOptions: [
        { text: 'No one present on site', value: 'no_on_site' },
        { text: 'Job rescheduled', value: 'job_resheduled' },
        { text: 'Other', value: 'other' },
      ],      
    };
  },  
  computed: {
    ...mapGetters({ currentBooking: 'app/getCurrentBookingData' }),
    ...mapGetters({ bookingDockets: 'app/getBookingDocketDetails' }),
    isInputDisabled() {
      return (
        this.currentBooking?.status === 'completed' ||
        this.currentBooking?.status === 'rejected' ||
        this.currentBooking?.status === null
      );
    }    
  },
  mounted() {
    this.originalVehicleRegistrationNumber = this.bookingDockets.vehicle_registration_number;
  },
  methods: {
    GoToPreviousPage() {
      this.$router.go(-1)
    },
    GoToNextPage() {
      if (this.currentBooking.status === 'to_do' || this.currentBooking.status === 'started') {
        const currentValue = this.bookingDockets.vehicle_registration_number;
        const originalValue = this.originalVehicleRegistrationNumber;
        if (currentValue !== originalValue) {
          bookingsMethods.saveVehicleRegistrationDetails(this.bookingDockets.id, this.bookingDockets.vehicle_registration_number)
        }
      }
      this.$router.push('/waste_col')
    },
    CancelJob() {
      if (this.currentBooking.status === 'to_do' || this.currentBooking.status === 'started') {
        bookingsMethods.cancelBookings(this.currentBooking.id, this.bookingDockets.reason, this.bookingDockets.reason_notes)
        this.$router.push('/driverprojects')
      }
    },
  },  
}
</script>
